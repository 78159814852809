html {
  height: 100%;
  scroll-padding-top: calc(4.5rem - 1px);
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(244, 98, 58, var(--bs-bg-opacity)) !important;
}

.btn-white {
  background-color: #f8f9fa !important;
  color: #f4623a !important;
}
.head {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 4.5rem);
}

.box > .icon {
  text-align: center;
  position: relative;
}
.box > .icon > .image {
  position: relative;
  z-index: 2;
  margin: auto;
  width: 88px;
  height: 88px;
  border: 8px solid #212529;
  line-height: 88px;
  border-radius: 50%;
  background: #f4623a;
  vertical-align: middle;
}
.box > .icon:hover > .image {
  background: #666;
}
.box > .icon > .image > i {
  font-size: 36px !important;
  color: #fff !important;
}
.box > .icon:hover > .image > i {
  color: #f4623a !important;
}
.box > .icon > .info {
  margin-top: -24px;
  background: rgba(0, 0, 0, 0.04);
  border: 1px solid #666;
  padding: 30px 0 10px 0;
  min-height: 163px;
  border-radius: 5%;
}
.box > .icon:hover > .info {
  background: rgba(0, 0, 0, 0.04);
  border-color: #f4623a;
  color: white;
}
.box > .icon > .info > h3.title {
  font-family: "Robot", sans-serif !important;
  font-size: 16px;
  color: #f4623a;
  font-weight: 700;
}
.box > .icon > .info > p {
  font-family: "Robot", sans-serif !important;
  font-size: 13px;
  color: #666;
  line-height: 1.5em;
  margin: 20px;
}
.box > .icon:hover > .info > h3.title,
.box > .icon:hover > .info > p,
.box > .icon:hover > .info > .more > a {
  color: white;
}
.box > .icon > .info > .more a {
  font-family: "Robot", sans-serif !important;
  font-size: 12px;
  color: #222;
  line-height: 12px;
  text-transform: uppercase;
  text-decoration: none;
}
.box > .icon:hover > .info > .more > a {
  color: #fff;
  padding: 6px 8px;
  background-color: #63b76c;
}
.box .space {
  height: 30px;
}
.text-justify {
  text-align: justify !important;
}

#sidebar > li > a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #6c757d;
  border-left: 1px solid #f4623a;
}

#sidebar > li > a:hover {
  color: #f4623a;
  border-left: 3px solid #f4623a;
}

.selected {
  color: #f4623a !important;
  border-left: 3px solid #f4623a !important;
}

.separation {
  border-top: 1px solid #f4623a;
  opacity: 0.25;
}

.sticky-top {
  top: 150px !important;
}

#lng-dropdown {
  line-height: 3em;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 5px;
}
.downloadCard {
  border-radius: 0px !important;
  box-shadow: none;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(244, 98, 58, 0.4);
}

.downloadCard:hover {
  box-shadow: none;
}

.downloadBox:hover {
  border: 1px solid #9fb4cc !important;
}

.card-icon,
.card-label {
  padding-right: 10px;
}

.card-second-title {
  padding: 5px;
  border-left: 3px solid #f4623a;
  border-right: 3px solid #f4623a;
  text-transform: uppercase;
}
.badge {
  position: absolute;
  top: 0;
  right: 0;
  text-transform: uppercase;
}

.custom-table-responsive {
  background-color: #efefef;
  padding: 20px;
  border-radius: 4px;
}

.custom-table {
  min-width: 90%;
}

.custom-table thead tr,
.custom-table thead th {
  border-top: none;
  border-bottom: none !important;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
.custom-table tbody th,
.custom-table tbody td {
  color: #777;
  font-weight: 400;
  padding-bottom: 20px;
  padding-top: 20px;
  font-weight: 300;
}

.custom-table tbody tr:not(.spacer) {
  border-radius: 7px;
  overflow: hidden;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.custom-table tbody tr:not(.spacer):hover {
  -webkit-box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
}
.custom-table tbody tr th,
.custom-table tbody tr td {
  background: #fff;
  border: none;
}
.custom-table tbody tr th:first-child,
.custom-table tbody tr td:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.custom-table tbody tr th:last-child,
.custom-table tbody tr td:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.custom-table tbody tr.spacer td {
  padding: 0 !important;
  height: 10px;
  border-radius: 0 !important;
  background: transparent !important;
}
/***** Download button *****/

#download {
  background: #ffffff;
  border: solid 1px #e6e6e6;
  border-radius: 2px;
  display: inline-block;
  height: 100px;
  line-height: 100px;
  margin: 5px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 100px;
}

#download span {
  background: #f4623a;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  line-height: normal;
  padding: 5px 10px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}

#download span:last-child {
  margin-left: -20px;
}

#download:before,
#download:after {
  background: #ffffff;
  border: solid 3px #9fb4cc;
  border-radius: 4px;
  content: "";
  display: block;
  height: 35px;
  left: 50%;
  margin: -17px 0 0 -12px;
  position: absolute;
  top: 50%;
   width: 25px;
}

#download:hover:before,
#download:hover:after {
  background: #e2e8f0;
}

#download:before {
  margin: -23px 0 0 -5px;
}

#download:hover {
  background: #e2e8f0;
  border-color: #9fb4cc;
}

#download:active {
  background: #dae0e8;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.25);
}

#download span:first-child {
  display: none;
}

#download:hover span:first-child {
  display: inline-block;
}

#download:hover span:last-child {
  display: none;
}
