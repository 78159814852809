.page-section {
  padding: 8rem 0;
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.btn-xl {
  padding: 1.25rem 2.25rem !important;
  font-size: 0.85rem !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  border: none !important;
  border-radius: 10rem !important;
  color: #fff;
}

hr.divider {
  height: 0.2rem;
  max-width: 3.25rem;
  margin: 1.5rem auto;
  background-color: #f4623a;
  opacity: 1;
}

hr.divider-light {
  background-color: #fff;
}

header.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 4.5rem);
  background: linear-gradient(
      to bottom,
      rgba(92, 77, 66, 0.8) 0%,
      rgba(92, 77, 66, 0.8) 100%
    ),
    url("../../assets/img/bg-masthead.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
header.masthead h1,
header.masthead .h1 {
  font-size: 2.25rem;
}
@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    min-height: 40rem;
    padding-top: 4.5rem;
    padding-bottom: 0;
  }
  header.masthead p {
    font-size: 1.15rem;
  }
  header.masthead h1,
  header.masthead .h1 {
    font-size: 3rem;
  }
}
@media (min-width: 1200px) {
  header.masthead h1,
  header.masthead .h1 {
    font-size: 3.5rem;
  }
}
